import React from "react";
import Router from './routes/Router';
import ReactDOM from "react-dom";

import "./styles/reset.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router />
    </React.StrictMode>
);