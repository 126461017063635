import { atom, useResetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

export const accessTokenState = atom({
  key: "accessTokenState",
  default: "",
});

export const refreshTokenState = atom({
  key: "refreshTokenState",
  default: "",
});

//로그아웃 함수
export const useLogout = () => {
  const history = useNavigate();

  const resetAccessToken = useResetRecoilState(accessTokenState);
  const resetRefreshToken = useResetRecoilState(refreshTokenState);

  return () => {
    resetAccessToken();
    resetRefreshToken();
    localStorage.clear();
    history('/login');
  };
};
