import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {ShopOutlined, UserOutlined, ShoppingCartOutlined, SlidersOutlined} from "@ant-design/icons";
//회원 전용 루트
import { Navigate } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: "/",
    component: () => {
      if (localStorage.getItem("user")) {
        return <Navigate to="/home" />
      } else {
        return <Navigate to="/login" />
      }
    },
  },
  {
    exact: true,
    path: "/login",
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/home",
    sidebar: {
      label: "eBT 웹발주",
    },
    component: loadable(() => import("../pages/home/Index")),
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        menuNo: "E001001001",
        sidebar: {
          label: "회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        menuNo: "E002001001",
        sidebar: {
          label: "상품조회",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        menuNo: "E002001001",
        component: loadable(() => import("../pages/product/Detail")),
      },
      {
        exact: true,
        path: "/sale",
        sidebar: {
          label: "판매가관리",
        },
        menuNo: "E002001002",
        component: loadable(() => import("../pages/product/Sale")),
      },
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리관리",
        },
        menuNo: "E002001003",
        component: loadable(() => import("../pages/product/Category")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "주문 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "전체주문조회",
        },
        menuNo: "E003001001",
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery",
        sidebar: {
          label: "배송관리",
        },
        menuNo: "E003001002",
        component: loadable(() => import("../pages/order/Delivery")),
      },
      {
        exact: true,
        path: "/cancel",
        sidebar: {
          label: "취소관리",
        },
        menuNo: "E003002001",
        component: loadable(() => import("../pages/order/IssueList")),
      },
      {
        exact: true,
        path: "/exchange",
        sidebar: {
          label: "교환관리",
        },
        menuNo: "E003002002",
        component: loadable(() => import("../pages/order/IssueList")),
      },
      {
        exact: true,
        path: "/return",
        sidebar: {
          label: "반품관리",
        },
        menuNo: "E003002003",
        component: loadable(() => import("../pages/order/IssueList")),
      },
    ],
  },
];
