import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routes";
import ServiceWrapper from "../components/ServiceWrapper";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                {publicRoutes.map(({ exact, path, component: Component, ...otherProps }) => (
                    <Route
                        key={`public-route-${path}`}
                        path={path}
                        element={<Component {...otherProps} />}
                    />
                ))}
                {privateRoutes.map(({ exact, path, component: Component, children }) =>
                    children ? (
                        children.map((child) =>
                            child.sub ? (
                                child.sub.map((subChild) => (
                                    <Route
                                        key={`private-route-${path}-${child.path}-${subChild.path}`}
                                        path={`${path}${child.path}${subChild.path}`}
                                        element={
                                            <ServiceWrapper>
                                                <Component/>
                                                <subChild.component/>
                                            </ServiceWrapper>
                                        }
                                    />
                                ))
                            ) : (
                                <Route
                                    key={`private-route-${path}-${child.path}`}
                                    path={`${path}${child.path}`}
                                    element={
                                        <ServiceWrapper>
                                            <child.component/>
                                        </ServiceWrapper>
                                    }
                                />
                            )
                        )
                    ) : (
                        <Route
                            key={`private-route-${path}`}
                            path={path}
                            element={
                                <ServiceWrapper>
                                    <Component/>
                                </ServiceWrapper>
                            }
                        />
                    )
                )}
            </Routes>
        </BrowserRouter>
    );
}