import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Layout, Menu, Popconfirm, Popover, message, Avatar } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";

import { privateRoutes } from "../routes/routes";
import constant from "../data/constant";
import { useLogout } from "../recoil/auth";
import usePost from "../api/usePost";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const ServiceWrapper = ({ children }) => {
    const navigate = useNavigate();

    const logout = useLogout();
    const location = useLocation();

    const [menuNo, setMenuNo] = useState('');
    const [privateRoute, setPrivateRoute] = useState(privateRoutes);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isVisibleProfilePopover, setIsVisibleProfilePopover] = useState(false);
    const [adminNo, setAdminNo] = useState('');
    const [adminGrpCd, setAdminGrpCd] = useState('');
    const [update, setUpdate] = useState(1);

    const pathName = location.pathname;
    const rootPath = pathName.substring(0, pathName.indexOf("/", 2));
    const pathArray = pathName.split("/")

    const openKey = [];
    openKey[0] = pathArray.length > 2 ? rootPath : pathName;
    if (pathArray.length > 3) {
        openKey[1] = '/' + pathArray[1] + '/' + pathArray[2]
    }

    const transFormData = () => {
        const newFormData = {
            menuCd: menuNo,
            adminNo: adminNo,
            adminGrpCd: adminGrpCd,
        };

        const formdata = new FormData();
        Object.entries(newFormData).forEach(([key, value]) => {
            formdata.append(key, value);
        });

        return formdata;
    };

    const adminData = usePost({url: adminNo && pathName !== '/home' && menuNo ? `/cm/am/au/ajaxChkAuthMenu.do?update=${update}` : '', formDataItem: transFormData()});

    useEffect(() => {
        if (!localStorage.getItem("user")) {
            message.error("접근 권한이 없습니다")
            navigate('/login')
            return false;
        } else {
            const userData = JSON.parse(localStorage.getItem("user"));
            setAdminNo(userData?.adminNo);
            setAdminGrpCd(userData?.adminGrpCd);
            setMenuNo(findMenuNoByPath());
            setUpdate(update + 1)
        }
    },[location.pathname]);

    useEffect(() => {
        if (adminData && adminData?.result) {
            if (adminData?.result === 'FAIL') {
                message.error(adminData?.msg)
                navigate('/')
            }
        }
    },[adminData]);

    function findMenuNoByPath() {
        let menuNo_ = null;

        for (const privateRoute_ of privateRoutes) {
            if (privateRoute_?.children) {
                var parentPath = privateRoute_?.path
                for (const children of privateRoute_?.children) {
                    if (menuNo_ === null && parentPath + children?.path === location.pathname) {
                        menuNo_ = children?.menuNo;
                    }
                }
            }
        }

        return menuNo_;
    }

    return (
        <Layout>
            <Sider
                collapsed={isCollapsed}
                onCollapse={(collapsedState) => setIsCollapsed(collapsedState)}
                width={260}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    background: "white",
                    borderRight: "1px solid #f0f0f0",
                }}
            >
                {!isCollapsed && (
                    <LogoImage src={"/assets/images/logo_b.png"} />
                )}
                <Menu
                    mode="inline"
                    activeKey={location.pathname}
                    defaultOpenKeys={openKey}
                    selectedKeys={[location.pathname]}
                >
                    {privateRoute.map((parentRoute) =>
                        (
                            parentRoute.children ? (
                                <SubMenu
                                    key={parentRoute.path}
                                    icon={Object(parentRoute.sidebar).icon}
                                    title={Object(parentRoute.sidebar).label}
                                    style={{ fontSize: 14 }}
                                >
                                    {parentRoute.children.map(
                                        ({ visible = true, ...childrenRoute }) =>
                                            childrenRoute?.sub ? (<SubMenu
                                                    key={`${parentRoute.path}${childrenRoute.path}`}
                                                    title={Object(childrenRoute.sidebar).label}
                                                    style={{ fontSize: 14 }}
                                                >
                                                    {childrenRoute.sub.map(({ visible = true, ...sub }) =>
                                                            visible && (
                                                                <Menu.Item
                                                                    key={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                    icon={Object(sub.sidebar).icon}
                                                                >
                                                                    <NavLink
                                                                        to={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                        className="nav-text"
                                                                        style={{ fontSize: 14 }}
                                                                    >
                                                                        {Object(sub.sidebar).label}
                                                                    </NavLink>
                                                                </Menu.Item>
                                                            )
                                                    )}
                                                </SubMenu>)
                                                : (
                                                    visible && (
                                                        <Menu.Item
                                                            key={`${parentRoute.path}${childrenRoute.path}`}
                                                            icon={Object(childrenRoute.sidebar).icon}
                                                        >
                                                            <NavLink
                                                                to={`${parentRoute.path}${childrenRoute.path}`}
                                                                className="nav-text"
                                                                style={{ fontSize: 14 }}
                                                            >
                                                                {Object(childrenRoute.sidebar).label}
                                                            </NavLink>
                                                        </Menu.Item>
                                                    )
                                                )
                                    )}
                                </SubMenu>
                            )  : (
                                <Menu.Item
                                    key={parentRoute.path}
                                    icon={Object(parentRoute.sidebar).icon}
                                >
                                    <NavLink
                                        to={parentRoute.path}
                                        className="nav-text"
                                        style={{ fontSize: 14 }}
                                    >
                                        {Object(parentRoute.sidebar).label}
                                    </NavLink>
                                </Menu.Item>
                            )
                        )
                    )}
                </Menu>
            </Sider>
            <Layout
                style={{
                    marginLeft: isCollapsed ? 80 : 260,
                    transition: "all 0.2s",
                    minHeight: "100vh",
                    backgroundColor: "white",
                }}
            >
                <Header
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 15px",
                        backgroundColor: "white",
                        boxShadow: "0 2px 8px #f0f1f2",
                    }}
                >
                    <MenuOutlined
                        style={{ fontSize: 20 }}
                        onClick={() => setIsCollapsed((prevState) => !prevState)}
                    />
                    <Popover
                        trigger="click"
                        placement="bottomRight"
                        content={
                            <>
                                <PopoverContents>
                                    <Popconfirm
                                        title="로그아웃 하시겠습니까?"
                                        onConfirm={() => {
                                            localStorage.clear();
                                            navigate('/login');
                                        }}
                                        okText="확인"
                                        cancelText="취소"
                                    >
                                      <span style={{ color: "black", cursor: "pointer" }}>
                                        로그아웃
                                      </span>
                                    </Popconfirm>
                                </PopoverContents>
                            </>
                        }
                        visible={isVisibleProfilePopover}
                        onVisibleChange={(visibleState) =>
                            setIsVisibleProfilePopover(visibleState)
                        }
                    >
                        < UserOutlined/>
                    </Popover>
                </Header>
                <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                    {children}
                </Content>
                <Footer style={{ textAlign: "center" }}>{constant.footerText}</Footer>
            </Layout>
        </Layout>
    );
};

const PopoverContents = styled.div`
  width: 150px;
`;

const LogoImage = styled.img`
  width: 100%;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 40px;
`;

export default ServiceWrapper;
